@import "./mixins.scss";

.mdc-button {
    &:not(:disabled),
    &:disabled {
        @include s();

        &.mat-transparent {
            --mdc-filled-button-label-text-color: var(--dark-color);
            --mdc-filled-button-container-color: transparent;
            --mat-filled-button-state-layer-color: transparent;
        }

        &.mat-dark {
            --mdc-filled-button-label-text-color: var(--dark-color-contrast);
            --mdc-filled-button-container-color: var(--dark-color);
            --mat-filled-button-state-layer-color: var(--dark-color);
        }

        &.mat-dark-tint {
            --mdc-filled-button-label-text-color: var(--dark-color-contrast);
            --mdc-filled-button-container-color: var(--dark-color-tint);
            --mat-filled-button-state-layer-color: var(--dark-color-tint);
        }

        &.mat-dark-shade {
            --mdc-filled-button-label-text-color: var(--dark-color-contrast);
            --mdc-filled-button-container-color: var(--dark-color-shade);
            --mat-filled-button-state-layer-color: var(--dark-color-shade);
        }

        &.mat-light {
            --mdc-filled-button-label-text-color: var(--light-color-contrast);
            --mdc-filled-button-container-color: var(--light-color);
            --mat-filled-button-state-layer-color: var(--light-color);
        }

        &.mat-background-shade {
            --mdc-filled-button-label-text-color: var(--dark-color);
            --mdc-filled-button-container-color: var(--background-color-shade);
            --mat-filled-button-state-layer-color: var(--background-color-shade);
        }

        &.mat-gray-shade {
            --mdc-filled-button-label-text-color: var(--gray-color-contrast);
            --mdc-filled-button-container-color: var(--gray-color-shade);
            --mat-filled-button-state-layer-color: var(--gray-color-shade);
        }

        &.mat-gray-tint {
            --mdc-filled-button-label-text-color: var(--gray-color-contrast);
            --mdc-filled-button-container-color: var(--gray-color-tint);
            --mat-filled-button-state-layer-color: var(--gray-color-tint);
        }

        &.mat-accent {
            --mdc-filled-button-label-text-color: var(--accent-color-contrast);
            --mdc-filled-button-container-color: var(--accent-color);
            --mat-filled-button-state-layer-color: var(--accent-color);
        }

        &.mat-accent-shade {
            --mdc-filled-button-label-text-color: var(--accent-color-contrast);
            --mdc-filled-button-container-color: var(--accent-color-shade);
            --mat-filled-button-state-layer-color: var(--accent-color-shade);
        }

        &.mat-accent-background {
            --mdc-filled-button-label-text-color: var(--accent-color-shade);
            --mdc-filled-button-container-color: var(--accent-color-background);
            --mat-filled-button-state-layer-color: var(--accent-color-background);
        }

        &.mat-danger {
            --mdc-filled-button-label-text-color: var(--danger-color-contrast);
            --mdc-filled-button-container-color: var(--danger-color);
            --mat-filled-button-state-layer-color: var(--danger-color);
        }

        &.--is-success {
            --mdc-filled-button-label-text-color: var(--green-color);
            --mdc-filled-button-container-color: var(--green-color-background);
        }
    }
}

@import "src/app/theme/mixins.scss";

table {
    --border-radius: var(--radius-XL);

    &:not(.--no-min-column-width) {
        --column-min-width: 200px;
    }

    &.mat-mdc-table.cdk-table {
        border-radius: var(--border-radius);
        background-color: transparent;

        thead {
            .mat-mdc-header-row {
                .mat-mdc-header-cell {
                    @include s;

                    color: var(--dark-color-tint);

                    min-width: var(--column-min-width);
                    vertical-align: middle;

                    &:first-child {
                        border-top-left-radius: var(--border-radius);
                    }

                    &:last-child {
                        border-top-right-radius: var(--border-radius);
                    }
                }
            }
        }

        tbody {
            .mat-mdc-row.cdk-row {
                .mat-mdc-cell.cdk-cell {
                    @include paragraph;

                    vertical-align: middle;

                    span {
                        @include truncate-text-multi-line(4);
                    }

                    &.--is-disabled {
                        background: var(--background-color);
                    }
                }
            }
        }
    }
}

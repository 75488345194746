@import "src/app/theme/mixins.scss";

.form {
    height: 100%;

    display: flex;
    flex-direction: column;

    max-width: 600px;
    border-radius: 20px;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: sticky;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 5;
        background-color: var(--background-color-tint);
        padding: var(--space-L);
        box-shadow: 0px 5px 10px -5px var(--background-color-shade);
        border-radius: 20px 20px 0 0;
    }

    &__body {
        padding: var(--space-L);
        background-color: var(--background-color-tint);
        @include hiddenScroll;
    }

    &__footer {
        position: sticky;
        bottom: 0;
        width: 100%;
        background-color: var(--background-color-tint);
        padding: var(--space-L);
        z-index: 1;
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        gap: var(--space-L);
        box-shadow: 0px -5px 10px -5px var(--background-color-shade);
        border-radius: 0 0 20px 20px;

        &__buttons {
            display: flex;
            align-items: center;

            button {
                margin-left: var(--space-L);
            }
        }
    }

    &__title {
        @include m;

        display: block;
        margin-bottom: var(--space-M);
    }

    &__label {
        @include s;

        display: block;
        margin-bottom: var(--space-S);
        color: var(--background-color-contrast);

        &.--top-space {
            margin-top: var(--space-M);
        }
    }

    &__separator {
        width: 100%;
        border-bottom: 1px solid var(--background-color-shade);
        margin-bottom: var(--space-L);

        &.--top-space {
            margin-top: var(--space-L);
        }
    }
}

@import "src/app/theme/mixins.scss";

.mat-expansion-panel {
    box-shadow: none !important;

    .mat-expansion-panel-header {
        padding: 2px;

        flex-direction: row-reverse;
        align-items: flex-start;
        gap: var(--space-L);

        background: transparent !important;

        --mat-expansion-header-text-color: var(--background-color-contrast);
        --mat-expansion-header-indicator-color: var(--background-color-contrast);

        --mat-expansion-header-expanded-state-height: 32px;
        --mat-expansion-header-collapsed-state-height: 32px;

        .mat-expansion-indicator {
            display: flex;
            transform-origin: 50% 80%;
        }

        .mat-expansion-panel-header-title {
            @include xs;
        }
    }

    .mat-expansion-panel-body {
        padding: 0;
    }
}

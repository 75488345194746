@import "./mixins.scss";

mat-slide-toggle {
    width: 100%;
    padding: var(--space-M) var(--space-L);
    background: var(--light-color);
    border-radius: var(--radius-L);

    .mdc-form-field {
        .mdc-switch {
            &__track {
                &::before,
                &::after {
                    background: rgba($color: #000000, $alpha: 0.38) !important;
                }
            }

            &__handle-track {
                .mdc-elevation-overlay {
                    background-color: var(--accent-color-contrast);
                }
            }

            &__ripple {
                display: none;
            }

            &--checked {
                .mdc-switch__handle-track {
                    .mdc-elevation-overlay {
                        background-color: var(--accent-color);
                    }
                }
            }
        }

        .mdc-label {
            padding-left: var(--space-M);

            .mat-slide-toggle__texts {
                display: flex;
                flex-direction: column;
                gap: var(--space-S);

                &__title {
                    @include s();
                    color: var(--dark-color-tint);
                }

                &__subtitle {
                    @include xs();
                    color: var(--background-color-contrast);
                }
            }
        }
    }
}

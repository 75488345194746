.filepond--credits {
    display: none;
}

.filepond--panel,
.filepond--item-panel,
.filepond--image-preview,
.filepond--image-preview-wrapper,
.filepond--image-preview-overlay,
.filepond--image-preview-overlay-idle,
.filepond--image-preview-overlay-success,
.filepond--image-preview-overlay-failure {
    background: transparent !important;
    background-color: transparent !important;
    color: transparent !important;
    box-shadow: none !important;
}

.filepond--panel-root {
    .filepond--panel-top,
    .filepond--panel-center,
    .filepond--panel-bottom {
        background: var(--background-color);
        background-color: var(--background-color);
    }

    .filepond--panel-top,
    .filepond--panel-bottom {
        border-radius: 0 !important;
    }
}

.filepond--panel-bottom.filepond--panel-root {
    border-bottom: 1px solid var(--background-color-shade) !important;
}

.filepond--file {
    padding: var(--space-M) 0;
    margin-top: var(--space-M);

    [data-align*="right"] {
        right: 0 !important;
    }

    [data-align*="left"] {
        left: 0 !important;
    }
}

.filepond--file-info-main,
.filepond--file-info-sub,
.filepond--file-status-main,
.filepond--file-status-sub {
    display: none;
}

.filepond--file-action-button {
    $size: 24px;

    width: $size !important;
    height: $size !important;

    background-color: var(--gray-color) !important;

    &:focus,
    &:hover {
        cursor: pointer;
        box-shadow: none !important;
    }
}

.filepond--root.filepond--hopper {
    margin-bottom: 0;
}

.filepond--image-preview-wrapper {
    top: var(--space-M) !important;
}

.filepond--drop-label {
    justify-content: flex-start !important;

    label {
        font-size: 0.875rem !important;
        padding: var(--space-L) !important;
        text-align: start !important;
    }
}

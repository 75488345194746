@import "src/app/theme/breakpoints.scss";

.page {
    --position-header: var(--header-position, sticky);
    --top-position-header: var(--header-top-position, var(--space-L));

    max-width: 1400px;
    max-height: 100vh;
    margin: auto;
    padding: var(--space-L);
    background-color: var(--background-color);

    app-header {
        position: var(--position-header);
        top: var(--top-position-header);
        z-index: 10;
    }

    @media screen and (max-width: $breakpoint-small-max) {
        max-height: auto;

        app-header {
            display: block;
            position: relative;
            top: inherit;
            z-index: inherit;
        }
    }
}

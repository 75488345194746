@import "src/app/theme/mixins.scss";

.content-variable-chip {
    width: fit-content;

    display: inline-flex !important;

    &__content {
        position: relative;

        $icon-size: 0.9rem;

        font-size: 100%;

        width: fit-content;

        display: flex;
        align-items: center;
        justify-content: center;

        padding: var(--space-XS) var(--space-M) var(--space-XS) calc(var(--space-M) + var(--space-S) + #{$icon-size});

        border-radius: var(--radius-XL);
        background: var(--accent-color-background);

        &__icon {
            position: absolute;

            left: var(--space-M);

            height: $icon-size;
            color: var(--accent-color-shade);
            border: none;
            margin: 0;
        }

        &__text {
            @include paragraph;
            white-space: nowrap;
            height: 0.9rem;
            line-height: 1;
            text-transform: capitalize;
            color: var(--accent-color-shade);
        }
    }
}

@import "src/app/theme/mixins.scss";

.mat-mdc-chip.mat-mdc-standard-chip.mdc-evolution-chip {
    margin: 0;
    border-radius: var(--radius-M);
    padding: var(--space-M) var(--space-L);

    --mdc-chip-disabled-label-text-color: var(--light-color-contrast);
    --mdc-chip-elevated-container-color: var(--background-color-shade);
    --mdc-chip-elevated-selected-container-color: var(--background-color-shade);
    --mdc-chip-elevated-disabled-container-color: var(--background-color-shade);
    --mdc-chip-flat-disabled-selected-container-color: var(--background-color-shade);
    --mdc-chip-focus-state-layer-color: transparent;
    --mdc-chip-hover-state-layer-color: transparent;
    --mdc-chip-selected-hover-state-layer-color: transparent;
    --mdc-chip-focus-state-layer-opacity: 1;
    --mdc-chip-selected-focus-state-layer-color: transparent;
    --mdc-chip-selected-focus-state-layer-opacity: 1;
    --mdc-chip-label-text-color: var(--light-color-contrast);
    --mdc-chip-selected-label-text-color: var(--accent-color-shade);
    --mdc-chip-with-icon-icon-color: var(--light-color-contrast);
    --mdc-chip-with-icon-disabled-icon-color: var(--light-color-contrast);
    --mdc-chip-with-icon-selected-icon-color: var(--accent-color-shade);
    --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: var(--light-color-contrast);
    --mdc-chip-with-trailing-icon-trailing-icon-color: var(--light-color-contrast);
    --mat-chip-selected-disabled-trailing-icon-color: var(--accent-color-shade);
    --mat-chip-selected-trailing-icon-color: var(--accent-color-shade);

    .mat-mdc-chip-action {
        display: flex;
        align-items: center;
        gap: var(--space-M);
        padding: 0;

        .mat-mdc-chip-graphic {
            padding: 0;
        }

        .mdc-evolution-chip__text-label {
            @include s;
            text-transform: capitalize;
        }
    }
}

nav {
    &.mat-mdc-tab-nav-bar {
        .mat-mdc-tab-link-container {
            .mat-mdc-tab-list {
                .mat-mdc-tab-links {
                    .mat-mdc-tab-link {
                        --mat-tab-header-active-label-text-color: var(--accent-color-tint);
                        --mat-tab-header-inactive-label-text-color: var(--background-color-contrast);
                        --mdc-tab-indicator-active-indicator-color: var(--light-color-shade);

                        &.mdc-tab--active {
                            --mdc-tab-indicator-active-indicator-color: var(--accent-color-tint);
                        }

                        .mdc-tab__ripple::before {
                            opacity: 0;
                        }

                        .mdc-tab-indicator__content {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}

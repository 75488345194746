@mixin xl() {
    font-size: 2.1875rem;
    font-weight: 700;
    font-style: normal;
    line-height: 2.1875rem;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0;
}

@mixin l() {
    font-size: 1.25rem;
    font-weight: 500;
    font-style: normal;
    line-height: 1.5rem;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0;
}

@mixin m() {
    font-size: 1rem;
    font-weight: 500;
    font-style: normal;
    line-height: 1.375rem;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0;
}

@mixin s() {
    font-size: 0.875rem;
    font-weight: 500;
    font-style: normal;
    line-height: 1.125rem;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0;
}

@mixin paragraph() {
    font-size: 0.875rem;
    font-weight: 400;
    font-style: normal;
    line-height: 1.28;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0;
}

@mixin xs() {
    font-size: 0.75rem;
    font-weight: 500;
    font-style: normal;
    line-height: 0.875rem;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0;
}

@mixin truncate-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin truncate-text-multi-line($lines-to-show) {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $lines-to-show;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin hiddenScroll {
    overflow: scroll;

    // Chrome, Safari
    &::-webkit-scrollbar {
        display: none;
    }

    // IE, Edge
    -ms-overflow-style: none;

    // Firefox
    scrollbar-width: none;
}

@import "src/app/theme/mixins.scss";

.mat-mdc-select {
    .mat-mdc-select-trigger {
        .mat-mdc-select-value {
            --mat-select-placeholder-text-color: var(--gray-color);
            --mat-select-enabled-trigger-text-color: var(--dark-color-tint);
            --mat-select-disabled-trigger-text-color: var(--dark-color-tint);
            --mat-select-enabled-arrow-color: var(--dark-color-tint);
            --mat-select-disabled-arrow-color: var(--dark-color-tint);
            --mat-select-focused-arrow-color: var(--dark-color-tint);
            --mat-select-invalid-arrow-color: var(--danger-color-shade);

            .mat-mdc-select-placeholder {
                @include s;
            }

            .mat-mdc-select-value-text {
                @include paragraph;
            }
        }
    }

    &.ng-invalid.ng-touched {
        .mat-mdc-select-trigger {
            .mat-mdc-select-value {
                .mat-mdc-select-placeholder {
                    --mat-select-placeholder-text-color: var(--danger-color-shade);
                }
            }
        }
    }
}

mat-optgroup {
    .mat-mdc-optgroup-label {
        @include m;
        color: var(--dark-color);
        position: relative;
        padding-left: 44px;
        background: var(--background-color-shade);

        &::before {
            content: "";
            position: absolute;
            left: var(--space-L);
            top: 50%;
            transform: translateY(-50%);
            width: 20px;
            height: 20px;
            background-size: cover;
            background-image: var(--img-url);
        }
    }
}

.cdk-overlay-pane {
    .mat-mdc-select-panel {
        position: absolute;
        top: 0;
        padding: 0;
        max-height: 600px;
    }
}

mat-option {
    &.mat-mdc-option {
        --mat-option-selected-state-label-text-color: var(--dark-color);
        --mat-option-label-text-color: var(--dark-color);
        --mat-option-hover-state-layer-color: transparent;
        --mat-option-focus-state-layer-color: transparent;
        --mat-option-selected-state-layer-color: var(--accent-color-background);

        &.mat-mdc-option-multiple {
            display: flex;
            flex-direction: row-reverse;

            --mat-option-selected-state-layer-color: transparent;
        }

        .mdc-list-item__primary-text {
            @include m;
        }
    }

    &.--select-option {
        .mdc-list-item__primary-text {
            display: flex;
            flex-direction: column;

            :first-child {
                @include s;
            }

            :last-child {
                @include paragraph;
                color: var(--background-color-contrast);
            }
        }
    }
}

mat-pseudo-checkbox.mat-mdc-option-pseudo-checkbox {
    &.mat-pseudo-checkbox-full {
        margin-right: 0;

        --mat-full-pseudo-checkbox-selected-icon-color: var(--accent-color-background);
        --mat-full-pseudo-checkbox-selected-checkmark-color: var(--accent-color);
        --mat-full-pseudo-checkbox-unselected-icon-color: var(--gray-color-tint);
    }

    &.mat-pseudo-checkbox-minimal {
        --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--accent-color);
    }
}

.mat-select-trigger {
    display: flex;
    align-items: center;
    gap: var(--space-M);

    &__item {
        display: flex;
        align-items: center;

        height: var(--space-XL);

        padding: 0 var(--space-M);
        gap: var(--space-M);

        border-radius: var(--radius-M);
        background: var(--gray-color-contrast);

        &__icon {
            $size: 20px;
            width: $size;
            height: $size;
            margin: 0;
            border: none;
        }

        &__text {
            color: var(--gray-color-shade);
        }
    }
}

@import "src/app/theme/mixins.scss";

mat-form-field {
    width: 100%;

    .--hide {
        display: none;
    }

    img {
        max-height: 40px;
        border-radius: 4px;
        object-fit: cover;
        margin-top: 4px;
        border: 1px solid rgba(0, 0, 0, 0.15);

        &.--ratio-1-1 {
            aspect-ratio: 1 / 1;
            border-radius: 50%;
            width: 40px;
        }
        &.--ratio-2-1 {
            aspect-ratio: 2 / 1;
            width: 40px;
        }
        &.--ratio-4-1 {
            aspect-ratio: 4 / 1;
            height: 40px;
        }
        &.--ratio-11-4 {
            aspect-ratio: 11 / 4;
            height: 40px;
        }
    }

    input[type="file"] {
        display: none;
    }

    input[type="color"] {
        left: 0;
        position: absolute;
        width: 100%;
        border: 0;
        border-color: transparent;
        border-radius: 2px;
        overflow: hidden;
        padding: 0;
        -webkit-appearance: none;
    }

    &.--search {
        .mdc-line-ripple {
            display: none;
        }

        .mat-mdc-text-field-wrapper {
            border-radius: 100px;
            background-color: var(--background-color-tint);
        }

        .mat-mdc-form-field-icon-prefix {
            padding: 0;

            mat-icon,
            mat-spinner {
                $size: 20px;

                width: $size;
                height: $size;

                color: var(--background-color-contrast);
                padding: 0;
                margin: 0 var(--space-L);
            }
        }

        .mat-mdc-form-field-icon-suffix {
            mat-icon {
                color: var(--background-color-contrast);
            }
        }

        .mat-mdc-form-field-subscript-wrapper {
            display: none;
        }
    }

    .mdc-text-field {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;

        --mdc-filled-text-field-container-color: var(--background-color);
        --mdc-filled-text-field-disabled-container-color: var(--background-color);

        &--no-label {
            .mat-mdc-form-field-infix {
                padding: 0;
                display: flex;
                align-items: center;
            }
        }

        .mat-mdc-form-field-flex {
            .mat-mdc-form-field-infix {
                .mdc-floating-label {
                    @include s;
                    @include truncate-text;
                    --mdc-filled-text-field-label-text-color: var(--gray-color);
                    --mdc-filled-text-field-focus-label-text-color: var(--gray-color);
                    --mdc-filled-text-field-hover-label-text-color: var(--gray-color);
                    --mdc-filled-text-field-disabled-label-text-color: var(--gray-color);
                    --mdc-filled-text-field-error-label-text-color: var(--gray-color);
                    --mdc-filled-text-field-error-focus-label-text-color: var(--gray-color);
                    --mdc-filled-text-field-error-hover-label-text-color: var(--gray-color);
                }

                .mat-mdc-input-element {
                    @include paragraph;
                    @include truncate-text;
                    --mdc-filled-text-field-input-text-color: var(--dark-color-tint);
                    --mdc-filled-text-field-disabled-input-text-color: var(--dark-color-tint);
                    --mdc-filled-text-field-input-text-placeholder-color: var(--dark-color-tint);
                }
            }

            .mat-mdc-form-field-icon-suffix {
                --mat-form-field-trailing-icon-color: var(--background-color-contrast);
                --mat-form-field-disabled-trailing-icon-color: var(--gray-color);
            }
        }

        .mdc-line-ripple {
            &::before,
            &::after {
                --mdc-filled-text-field-active-indicator-height: 1px;
                --mdc-filled-text-field-focus-active-indicator-height: 1px;
                --mdc-filled-text-field-active-indicator-color: var(--background-color-shade);
                --mdc-filled-text-field-hover-active-indicator-color: var(--background-color-shade);
                --mdc-filled-text-field-disabled-active-indicator-color: var(--background-color-shade);
                --mdc-filled-text-field-focus-active-indicator-color: var(--background-color-contrast);
                --mdc-filled-text-field-error-active-indicator-color: var(--background-color-shade);
                --mdc-filled-text-field-error-focus-active-indicator-color: var(--background-color-contrast);
                --mdc-filled-text-field-error-hover-active-indicator-color: var(--background-color-shade);
            }
        }

        &__input {
            &::-webkit-calendar-picker-indicator {
                display: block;
            }
        }

        &--disabled {
            opacity: 0.6;
        }
    }

    &.ng-invalid.ng-touched {
        .mdc-text-field {
            .mat-mdc-form-field-flex {
                .mat-mdc-form-field-infix {
                    .mdc-floating-label {
                        --mdc-filled-text-field-error-label-text-color: var(--danger-color-shade);
                        --mdc-filled-text-field-error-focus-label-text-color: var(--danger-color-shade);
                        --mdc-filled-text-field-error-hover-label-text-color: var(--danger-color-shade);
                    }
                }
            }

            .mdc-line-ripple {
                &::before,
                &::after {
                    --mdc-filled-text-field-error-active-indicator-color: var(--danger-color-shade);
                    --mdc-filled-text-field-error-focus-active-indicator-color: var(--danger-color-shade);
                    --mdc-filled-text-field-error-hover-active-indicator-color: var(--danger-color-shade);
                }
            }
        }
    }

    .mat-mdc-form-field-focus-overlay {
        display: none;
    }
}

@import "./mixins.scss";

.mat-button-toggle-group.mat-button-toggle-group-appearance-standard {
    $size: 42px;

    height: $size;
    min-height: $size;

    border: none;
    border-radius: 100px;

    &.--full-width {
        width: 100%;
    }

    &.--s {
        $size: 30px;

        height: $size;
        min-height: $size;
    }

    &.--is-disabled {
        opacity: 0.6;
        pointer-events: none;
    }

    .mat-button-toggle {
        width: 100%;
        border: none !important;

        .mat-button-toggle-button {
            height: 100%;
        }

        &.--no-icon {
            .mat-pseudo-checkbox {
                display: none;
            }
        }
    }

    .mat-button-toggle-disabled {
        opacity: 0.5;
    }

    .mat-button-toggle-label-content {
        @include s();

        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        line-height: 0;
        padding: 0 calc(var(--space-L) * 1.5);

        color: var(--background-color-contrast);
        background-color: var(--background-color-shade);

        mat-icon {
            margin-right: var(--space-L);
        }
    }

    .mat-button-toggle-checked {
        .mat-button-toggle-label-content {
            color: var(--accent-color);
            background-color: var(--accent-color-background);

            .mat-pseudo-checkbox {
                --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--accent-color);
                --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: var(--accent-color);
            }
        }

        &.--green {
            .mat-button-toggle-label-content {
                color: var(--green-color);
                background-color: var(--green-color-background);

                .mat-pseudo-checkbox {
                    --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--green-color);
                    --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: var(--green-color);
                }
            }
        }

        &.--danger {
            .mat-button-toggle-label-content {
                color: var(--danger-color);
                background-color: var(--danger-color-background);

                .mat-pseudo-checkbox {
                    --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--danger-color);
                    --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: var(--danger-color);
                }
            }
        }
    }
}

.mat-mdc-progress-bar.mdc-linear-progress {
    $height: 16px;

    height: $height;
    border-radius: var(--radius-L);

    .mdc-linear-progress__bar {
        --mdc-linear-progress-active-indicator-height: calc(#{$height} - 4px);
    }

    .mdc-linear-progress__buffer {
        height: $height;
        border-radius: var(--radius-L);

        .mdc-linear-progress__buffer-bar {
            background: transparent;
        }
    }

    &.--green {
        .mdc-linear-progress__bar {
            .mdc-linear-progress__bar-inner {
                border-color: var(--green-color-shade);
            }
        }

        .mdc-linear-progress__buffer {
            background: var(--green-color-background);
        }
    }

    &.--orange {
        .mdc-linear-progress__bar {
            .mdc-linear-progress__bar-inner {
                border-color: var(--orange-color-shade);
            }
        }

        .mdc-linear-progress__buffer {
            background: var(--orange-color-background);
        }
    }

    &.--danger {
        .mdc-linear-progress__bar {
            .mdc-linear-progress__bar-inner {
                border-color: var(--danger-color-shade);
            }
        }

        .mdc-linear-progress__buffer {
            background: var(--danger-color-background);
        }
    }
}

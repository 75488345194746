@import "./mixins.scss";

.mat-mdc-menu-panel {
    .mat-mdc-menu-content {
        padding: 0;
    }

    .mat-mdc-menu-item {
        border-bottom: 1px solid var(--background-color-shade);

        .mat-menu-item-title {
            padding: var(--space-M) 0;
            @include s();
        }

        .mat-menu-item-subtitle {
            @include xs();
            color: var(--background-color-contrast);
        }

        mat-icon {
            $size: 20px;
            width: $size;
            height: $size;
            font-size: $size;
        }

        &.--is-color-danger {
            --mat-menu-item-icon-color: var(--danger-color);

            .mat-menu-item-title {
                color: var(--danger-color);
            }

            .mat-menu-item-subtitle {
                color: var(--danger-color);
            }
        }
    }
}

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$my-black-colors: (
    50: #e0e0e0,
    100: #b3b3b3,
    200: #808080,
    300: #4d4d4d,
    400: #333333,
    500: #1a1a1a,
    // default
    600: #000000,
    // default dark
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #4d4d4d,
    // lighter
    A200: #1a1a1a,
    // main
    A400: #000000,
    // darker
    A700: #000000,
    contrast: (
        50: #000000,
        100: #000000,
        200: #ffffff,
        300: #ffffff,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #ffffff,
        A400: #ffffff,
        A700: #ffffff,
    ),
);
$my-accent-colors: (
    50: #ece5fe,
    100: #d1bffc,
    200: #b294fa,
    300: #9369f8,
    400: #7b49f7,
    500: #6429f5,
    600: #5c24f4,
    700: #521ff2,
    800: #4819f0,
    900: #360fee,
    A100: #ffffff,
    A200: #eae6ff,
    A400: #beb3ff,
    A700: #a89aff,
    contrast: (
        50: #ece5fe,
        100: #d1bffc,
        200: #b294fa,
        300: #9369f8,
        400: #fff,
        500: #fff,
        600: #fff,
        700: #fff,
        800: #fff,
        900: #fff,
        A100: #360fee,
        A200: #360fee,
        A400: #360fee,
        A700: #360fee,
    ),
);
$my-red-colors: (
    50: #bc4b45,
    100: #af403a,
    200: #b9352e,
    300: #b3261e,
    400: #c1332cfe,
    500: #b3261e,
    // default
    600: #e53935,
    // default dark
    700: #d32f2f,
    800: #c62828,
    900: #a91717,
    A100: #7e251d,
    // lighter
    A200: #731212,
    // main
    A400: #d32f2f,
    // darker
    A700: #b32419,
    // darkest
    contrast:
        (
            50: #000000,
            100: #000000,
            200: #000000,
            300: #ffffff,
            400: #ffffff,
            500: #ffffff,
            600: #ffffff,
            700: #ffffff,
            800: #ffffff,
            900: #ffffff,
            A100: #000000,
            A200: #ffffff,
            A400: #ffffff,
            A700: #ffffff,
        ),
);
$jeripass-admin-app-primary: mat.define-palette($my-black-colors);
$jeripass-admin-app-accent: mat.define-palette($my-accent-colors);

// The warn palette is optional (defaults to red).
$jeripass-admin-app-warn: mat.define-palette($my-red-colors);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$jeripass-admin-app-theme: mat.define-light-theme(
    (
        color: (
            primary: $jeripass-admin-app-primary,
            accent: $jeripass-admin-app-accent,
            warn: $jeripass-admin-app-warn,
        ),
        typography: mat.define-typography-config(),
        density: 0,
    )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($jeripass-admin-app-theme);

/* You can add global styles to this file, and also import other style files */
/* stylelint-disable no-invalid-position-at-import-rule */
@import "./app/theme/reset.scss";
@import "./app/theme/variables.scss";

@import "./app/theme/mat-button.scss";
@import "./app/theme/mat-form-field.scss";
@import "./app/theme/mat-drawer-container.scss";
@import "./app/theme/mat-table.scss";
@import "./app/theme/mat-paginator.scss";
@import "./app/theme/mat-tab-nav-bar.scss";
@import "./app/theme/mat-slide-toggle.scss";
@import "./app/theme/mat-menu.scss";
@import "./app/theme/mat-dialog.scss";
@import "./app/theme/mat-button-toggle-group.scss";
@import "./app/theme/mat-chip-option.scss";
@import "./app/theme/mat-select.scss";
@import "./app/theme/mat-stepper.scss";
@import "./app/theme/mat-progress-bar.scss";
@import "./app/theme/mat-expansion-panel.scss";
@import "./app/theme/mat-hint.scss";

@import "./app/theme/page.scss";
@import "./app/theme/form.scss";
@import "./app/theme/table.scss";
@import "./app/theme/content-variable-chip.scss";

@import "./app/theme/filepond.scss";

@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";

html,
body {
    height: 100%;
}
body {
    font-size: 16px;
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    color: var(--dark-color);
    background-color: var(--background-color);
}

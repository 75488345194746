@import "src/app/theme/mixins.scss";
@import "src/app/theme/breakpoints.scss";

.mat-stepper-horizontal {
    --mat-stepper-container-color: var(--background-color);

    .mat-horizontal-stepper-wrapper {
        display: flex;
        flex-direction: column;
        gap: var(--space-XL);

        .mat-horizontal-stepper-header-container {
            margin: auto;
            gap: var(--space-L);
            @include hiddenScroll;

            .mat-step-header {
                pointer-events: none;

                height: 18px;
                min-width: fit-content;

                display: flex;
                align-items: center;
                justify-content: center;

                gap: var(--space-S);
                padding: var(--space-M) var(--space-L);

                border-radius: var(--radius-XL);
                border: 2px solid var(--gray-color-tint);
                background: var(--background-color-tint);

                &:hover {
                    border-radius: var(--radius-XL);
                }

                &[aria-disabled="true"] {
                    opacity: 0.5;
                    border: 2px solid var(--background-color-tint);
                }

                .mat-step-icon {
                    margin: 0;

                    color: transparent;
                    background-color: transparent;

                    .mat-step-icon-content {
                        span {
                            @include s;
                            color: var(--dark-color);
                        }
                    }

                    &.mat-step-icon-state-done {
                        background: var(--green-color-shade);

                        .mat-step-icon-content {
                            color: var(--green-color-contrast);
                        }
                    }
                }

                .mat-step-label {
                    min-width: fit-content;

                    .mat-step-text-label {
                        min-width: fit-content;
                        @include s;
                        color: var(--dark-color);
                    }
                }

                &[ng-reflect-state="done"] {
                    border: 2px solid var(--green-color-background);
                    background: var(--green-color-background);

                    .mat-step-label {
                        .mat-step-text-label {
                            color: var(--green-color-shade);
                        }
                    }
                }
            }

            .mat-stepper-horizontal-line {
                $width: 16px;

                width: $width;
                min-width: $width;
                max-width: $width;

                margin: 0px -12px;

                border-style: dotted;
                border-top-width: 4px;
                border-top-color: var(--light-color-shade);
            }
        }
    }

    @media screen and (max-width: $breakpoint-medium-max) {
        .mat-horizontal-stepper-wrapper {
            .mat-horizontal-stepper-header-container {
                margin: 0;
            }
        }
    }
}

@import "src/app/theme/mixins.scss";

.mdc-dialog.mat-mdc-dialog-container {
    --header-height: 54px;
    --footer-height: 164px;

    .mdc-dialog__surface.mat-mdc-dialog-surface {
        border-radius: var(--radius-L);
    }

    .modal {
        &__header {
            @include m;
            padding: var(--space-L);
        }

        &__content {
            max-height: calc(100vh - var(--header-height) - var(--footer-height));
            padding: var(--space-L);
            @include hiddenScroll;
        }

        &__footer {
            display: flex;
            flex-direction: column;
            gap: var(--space-L);
            padding: var(--space-L);

            &__buttons {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                gap: var(--space-M);
            }
        }
    }
}

@import "./mixins.scss";
@import "./breakpoints.scss";

.table {
    padding: var(--space-L);
    border-radius: var(--radius-XL);
    background: var(--light-color);

    &__header {
        display: flex;
        align-items: center;
        gap: var(--space-L);
        margin-bottom: var(--space-L);
        flex-wrap: wrap;

        &__title {
            @include m();
            color: var(--dark-color-tint);
        }

        &__button {
            margin-left: auto;
        }

        &__input {
            max-width: 400px;
        }

        &__filters {
            display: flex;
            align-items: center;
            gap: var(--space-S);
        }
    }

    &__table {
        @include hiddenScroll;

        border-radius: var(--radius-XL);
        background-color: var(--background-color-tint);

        &.--has-paginator {
            border-radius: var(--radius-XL) var(--radius-XL) 0 0;
        }

        &.--no-paginator {
            table {
                overflow: hidden;
            }
        }

        &__empty {
            td {
                @include s();
                color: var(--gray-color-shade);
                padding: var(--space-L);
            }
        }
    }

    @media screen and (max-width: $breakpoint-small-max) {
        &__header {
            &__button {
                margin-left: 0;
            }
        }
    }
}

@import "./mixins.scss";

:root {
    --background-color: #f7f7f7;
    --background-color-shade: #f2f2f2;
    --background-color-tint: #ffffff;
    --background-color-contrast: #727272;

    --dark-color: #333333;
    --dark-color-tint: #4b4a4a;
    --dark-color-shade: #000000;
    --dark-color-contrast: #fcfcfc;

    --light-color: #fbfbfb;
    --light-color-tint: #ffffff;
    --light-color-shade: #e3e3e3;
    --light-color-contrast: #333333;

    --gray-color: #a5a5a5;
    --gray-color-tint: #c6c6c6;
    --gray-color-shade: #727272;
    --gray-color-contrast: #ffffff;
    --gray-color-background: #f6f5f5;

    --danger-color: #b3261e;
    --danger-color-tint: #f53928;
    --danger-color-shade: #d32f2f;
    --danger-color-contrast: #ffffff;
    --danger-color-background: #ffdbd9;

    --accent-color: #6429f5;
    --accent-color-tint: #6a33f4;
    --accent-color-shade: #591cf4;
    --accent-color-contrast: #ffffff;
    --accent-color-background: #f7f4ff;

    --green-color: #5ddc90;
    --green-color-tint: #78f1a8;
    --green-color-shade: #38b96c;
    --green-color-contrast: #ffffff;
    --green-color-background: #e9fff2;

    --orange-color: #e79345;
    --orange-color-tint: #efa663;
    --orange-color-shade: #d37e2f;
    --orange-color-contrast: #0f0e0e;
    --orange-color-background: #fff0e2;

    // layout
    --space-XS: 2px;
    --space-S: 4px;
    --space-M: 8px;
    --space-L: 16px;
    --space-XL: 32px;
    --space-XXL: 64px;

    --radius-S: 2px;
    --radius-M: 5px;
    --radius-L: 10px;
    --radius-XL: 20px;
    --radius-XXL: 100px;
}

a {
    @include s();
    color: var(--ion-color-dark);
    text-decoration: underline;
}

.--is-clickable {
    cursor: pointer;
}

.--hidden-scroll {
    @include hiddenScroll();
}

.--xl {
    @include xl();
    color: var(--dark-color);
}

.--l {
    @include l();
    color: var(--dark-color);
}

.--m {
    @include m();
    color: var(--dark-color);
}

.--s {
    @include s();
    color: var(--dark-color);
}

.--paragraph {
    @include paragraph();
    color: var(--dark-color);
}

.--xs {
    @include xs();
    color: var(--dark-color);
}

.mat-drawer-container {
    min-height: 100vh;

    --mat-sidenav-content-background-color: var(--background-color);

    mat-drawer {
        &.mat-drawer-side {
            border: none;
        }
    }
}
